import React, { useEffect, useState } from 'react';
import supabase from '../config/supabaseClient';
import { Navigate } from 'react-router-dom';

function Wrapper({ children }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            setAuthenticated(!!session);
            setLoading(false);
        };

        getSession();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return authenticated ? <>{children}</> : <Navigate to="/signIn" />;
}

export default Wrapper;