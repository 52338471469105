import React from 'react'
import supabase  from '../config/supabaseClient';
import {useNavigate} from 'react-router-dom';
import Announcements from '.././components/LandingPage/Announcements'


function Dashboard() {

    const navigate = useNavigate();

    // async function signOut() of supabase.auth.signOut()
    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) console.log('Error logging out:', error.message);
        //navigate back to the landing page
        navigate('/')
    };

return (
    <div>
            <h2>Welcome to your dashboard!</h2>
            <Announcements></Announcements>
            <button className='mainBTN' onClick={signOut}>Sign Out</button>
    </div>
)
}
export default Dashboard