import { createClient } from '@supabase/supabase-js' // eslint-disable-line

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
    throw new Error('Missing Supabase environment variables');
} else {
    console.log('Supabase environment variables are set successfully');
}

const supabase = createClient(supabaseUrl, supabaseKey)

export default supabase


// import { createClient } from '@supabase/supabase-js' // eslint-disable-line

// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL; // eslint-disable-line
// const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY; // eslint-disable-line
// const storageAccessKey = process.env.REACT_APP_SUPABASE_STORAGE_ACCESS_KEY; // eslint-disable-line
// const storageSecretKey = process.env.REACT_APP_SUPABASE_STORAGE_SECRET_KEY; // eslint-disable-line

// if (!supabaseUrl || !supabaseKey || !storageAccessKey || !storageSecretKey) {
//     throw new Error('Missing Supabase environment variables'); // eslint-disable-line
// } else {
//     console.log('Supabase environment variables are set successfully'); // eslint-disable-line
// }

// const supabase = createClient(supabaseUrl, supabaseKey, storageAccessKey, storageSecretKey);

// export default supabase; // eslint-disable-line
