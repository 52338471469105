import {useEffect, useState} from 'react'
import supabase from '../config/supabaseClient';

import HeroBanner from '../components/LandingPage/HeroBanner'
import Schedule from '../components/LandingPage/Schedule'
import Nav from '../components/LandingPage/MainNav'
import Announcements from '../components/LandingPage/Announcements'
import Sacraments from '../components/LandingPage/Sacraments'
import FeedBack from '../components/LandingPage/FeedBack'
import SafeEnvironment from '../components/LandingPage/SafeEnvironment'
import PhotoGallery from '../components/LandingPage/PhotoGallery'
import LogOut from '../components/LogOut'


import '../modules/LandingPageStyles.css'





function LandingPage() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const user = await supabase.auth.getUser();
      setIsAuthenticated(!!user.data.user);
      console.log("Auth!!", !!user.data.user);
    };
    checkAuth();
  }, []);

  return (
    <div className='LandingPage-PageView'>

        {isAuthenticated && (
            <LogOut></LogOut>
        )}
        <HeroBanner></HeroBanner>
        <Schedule></Schedule>
        <Nav></Nav>
        <Announcements></Announcements>
        <PhotoGallery></PhotoGallery>
        <Sacraments></Sacraments>
        <SafeEnvironment></SafeEnvironment>
        <FeedBack></FeedBack>
    </div>

  )
}


export default LandingPage
