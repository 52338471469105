import supabase from '../../config/supabaseClient';
import { useEffect, useState } from 'react';

const Bulletin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  // states for edit input
  const [editTitle, setEditTitle] = useState("")
  const [editDate, setEditDate] = useState("");
  const [editTime, setEditTime] = useState("");
  const [editDetail, setEditDetail] = useState("");
  const [editFileName, setEditFileName] = useState("");


  
  useEffect(() => {
    const checkAuth = async () => {
      const user = await supabase.auth.getUser();
      setIsAuthenticated(!!user.data.user);
      console.log("Auth!!", !!user.data.user);
    };
    checkAuth();
  }, []);

  const [events, setEvents] = useState(null);
  const [visibleDetails, setVisibleDetails] = useState({}); // State to track visibility of details

  useEffect(() => {
    async function fetchRentals() {
      const { data, error } = await supabase.from('events').select('*');

      if (error) {
        setFetchError('Could not fetch events');
        setEvents(null);
        console.log(error);
      }

      if (data) {
        setEvents(data);
        setFetchError(null);
        console.log('Supabase Events:', data);
      }
    }
    fetchRentals();
  }, []);

  const toggleDetails = (id) => {
    setVisibleDetails((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle visibility for the specific event
    }));
  };


  const CreateEvent = async (e) => {
    e.preventDefault();
    console.log("Create Event was called");
    console.log(editFileName)

    // //get user
    // try {
    //   const { data, error } = await supabase.auth.getUser();
    //   if (error) {
    //   console.error("Error fetching user:", error);
    //   return null;
    //   }
    //   console.log("Fetched user:", data.user);
    //   setUserId(data.user)
    //   // return data.user;
    // } catch (err) {
    //   console.error("Unexpected error fetching user:", err);
    //   // return null;
    // }

    //get upload media
    let fileInput = document.getElementById('fileName');
    let file = fileInput?.files[0];
    console.log(file);
    let StorageLink = "";



    const { data, error } = await supabase.storage
      .from('events')
      .upload(`${file.name}`, file);

    if (error) {
      console.log(error);
    } else {
      console.log(data);
      const { data: signedUrlData, error: signedUrlError } = await supabase.storage
      .from('events')
      .createSignedUrl(`${file.name}`, 60 * 60 * 24 * 365); // 1 year in seconds

      if (signedUrlError) {
      console.log("Error creating signed URL:", signedUrlError);
      } else {
      StorageLink = signedUrlData.signedUrl;
      console.log("Signed URL (1 year):", StorageLink);
      setEditFileName(StorageLink)
      
      }
    }

    const { dataForm, errorForm } = await supabase
      .from('events')
      .insert([
        {
          title: editTitle,
          date: editDate,
          time: editTime,
          detail: editDetail,
          fileName: StorageLink,
        }
      ])
      .select();

    if (dataForm) {
      console.log("Event created successfully:", data);
      setEvents((prevEvents) => [...prevEvents, ...data]); // Update the events list with the new event
      setEditTitle("");
      setEditDate("");
      setEditTime("");
      setEditDetail("");
      setEditFileName("");
    }

    if (errorForm) {
      console.log("Error creating event:", error);
    }
  };

  const deleteHandler = async (e, id) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log("Delete was called", id);

    const { error } = await supabase
      .from('events')
      .delete()
      .eq('id', id); // Replace 'id' with the actual column name if different

    if (error) {
      console.error("Error deleting event:", error);
    } else {
      console.log("Event deleted successfully");
      setEvents((prevEvents) => prevEvents.filter((event) => event.id !== id)); // Update state to remove the deleted event
    }
  };
  

  return (
    <div className="Announcements-ViewPage">
      <h1>Announcements</h1>

      {/* render form if isAuthenticated */}
      {isAuthenticated && (
        <div>
          <form onSubmit={CreateEvent}>
            <div>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Title"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                id="date"
                name="date"
                placeholder="Date"
                value={editDate}
                onChange={(e) => setEditDate(e.target.value)}
              />
            </div>
            <div>
              <input
                type="text"
                id="time"
                name="time"
                placeholder="Time"
                value={editTime}
                onChange={(e) => setEditTime(e.target.value)}
              />
            </div>
            <div>
              <textarea
                id="detail"
                name="detail"
                placeholder="Detail"
                value={editDetail}
                onChange={(e) => setEditDetail(e.target.value)}
              ></textarea>
            </div>
            <div>
              <input
                type="file"
                id="fileName"
                name="fileName"
                placeholder="Image File Name"
                // onChange={(e) => setEditFileName(e.target.files[0]?.name || "")}
              />
            </div>

            <button type="submit">Add Event</button>
          </form>
        </div>
      )}

      {fetchError && <p>{fetchError}</p>}
      {events && (
        <ul>
          {events
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((event) => (
              <li key={event.id}>
                <p className="hidden">{event.orderNumber}</p>
               
                <div className="event-details">
                <img
                    src={`${event.fileName}`}
                    alt=""
                    className={visibleDetails[event.id] ? 'expanded-image' : 'hidden-image'}
                  />
                  <h2>{event.title}</h2>
                  <h3>{event.date}</h3>
                  <h3>{event.time}</h3>
                
                  {visibleDetails[event.id] && <p>{event.detail}</p>}
                </div>

                {isAuthenticated ? (
                  <div>
                    <button id={event.id}>Edit</button>
                    <button id={event.id} onClick={(e) => deleteHandler(e, event.id)}>Delete</button>
                  </div>
                ) : (
                  <button
                    className="MainBTN"
                    onClick={() => toggleDetails(event.id)}
                  >
                    {visibleDetails[event.id] ? 'Close' : 'Learn More'}
                  </button>
                )}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default Bulletin;
