import React from 'react'
import supabase  from '../config/supabaseClient';
// import {useNavigate} from 'react-router-dom';


function LogOut() {

  // const navigate = useNavigate();

// async function signOut() of supabase.auth.signOut()
const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log('Error logging out:', error.message);
    //navigate back to the landing page
    window.location.reload()
};

    
  return (
    <div>
      <h1>You are singed in as Admin</h1>
            <button className='mainBTN' onClick={signOut}>Sign Out</button>

    </div>
  )
}

export default LogOut