import './App.css';
import Dashboard from './views/Dashboard';
import LandingPage from './views/LandingPage';
// import {Router} from '@reach/router'
import UnderDevelopment from './views/UnderDevelopment';
import ParishStaff from './views/ParishStaff';
import OurMinistries from './views/OurMinistries';
import GiftShop from './views/GiftShop';
import FaithFormation from './views/FaithFormation';
import SummerEvents from './components/SummerEvents';
import Wrapper from './views/Wrapper';
import LogIn from './components/LogIn'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


// import Subscribe from './components/Subscribe';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/log-in" element={<LogIn />} />

          
          <Route
            path="/dashboard"
            element={
              <Wrapper>
                <Dashboard />
              </Wrapper>
            }
          />
          <Route path="/under-development" element={<UnderDevelopment />} />
          <Route path="/parish-staff" element={<ParishStaff />} />
          <Route path="/our-ministries" element={<OurMinistries />} />
          <Route path="/gift-shop" element={<GiftShop />} />
          <Route path="/faith-formation" element={<FaithFormation />} />
          <Route path="/summer-events-rsvp-results" element={<SummerEvents />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
