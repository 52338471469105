import React, { useState } from 'react';
import supabase  from '../config/supabaseClient';
import { useNavigate} from 'react-router-dom';


function LogIn(props) {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

   // password input style display none
   const [passwordInput, setPasswordInput] = useState("gird");  

  const handleRegister = async (e) => {
      e.preventDefault();
      const { user, error } = await supabase.auth.signInWithPassword({
          email,
          password,
      });
      if (error) {
          setError(error.message);
      } else {
          console.log('User registered:', user);
          navigate('/')
          return null;
      }
  }



return (
    <div className='signIn-container'>
            <h2 style={{fontSize: "1.3em"}}>Sign In</h2>
            <form onSubmit={handleRegister}>
                            <input onClick={() => setPasswordInput("grid")}
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Email:"
                            />
                    <div style={{display: passwordInput, rowGap: '10px'}}>
                            <input 
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    placeholder="Password:"
                            />
                    <button className='mainBTN' type="submit">Log In</button>
                    
                    </div>
            </form>
            {error && <p>{error}</p>}
    </div>
)
}


export default LogIn