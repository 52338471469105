import { useEffect, useState } from 'react';
import supabase from '../../config/supabaseClient';


function Homily() {

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        fetchDocs();
    }, []);
    
    useEffect(() => {
        const checkAuth = async () => {
          const user = await supabase.auth.getUser();
          setIsAuthenticated(!!user.data.user);
          console.log("Auth!!", !!user.data.user);
        };
        checkAuth();
      }, []);

    const [docs, setDocs] = useState(null);
    const [fetchError, setFetchError] = useState(null);

    const fetchDocs = async () => {
        const { data, error } = await supabase.from('docs').select('*');

        if (error) {
            setFetchError('Could not fetch docs');
            setDocs(null);
            console.log(error);
        }

        if (data) {
            setDocs(data);
            setFetchError(null);
            console.log('Supabase Docs:', data);
        }
    };

    

      

    const handleDocFormSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const formType = e.target.getAttribute('action'); // Identify which form was submitted
        console.log(`Form submitted: ${formType}`);

        const fileInput = e.target.querySelector('input[type="file"]');
        const file = fileInput?.files?.[0];
        console.log(file);

        if (!file) {
            console.log("No file selected");
            return;
        }

        let StorageLink = "";

        const { data, error } = await supabase.storage
            .from('docs')
            .upload(`${file.name}`, file);

        if (error) {
            console.log(error);
        } else {
            console.log(data);
            const { data: signedUrlData, error: signedUrlError } = await supabase.storage
                .from('docs')
                .createSignedUrl(`${file.name}`, 60 * 60 * 24 * 7); // 1 week in seconds

            if (signedUrlError) {
                console.log("Error creating signed URL:", signedUrlError);
            } else {
                StorageLink = signedUrlData.signedUrl;
                console.log("Signed URL (1 year):", StorageLink);
                // setFile(StorageLink);
            }
        }

        if (formType === "bulletin"){
            console.log("Bulletin submit")
            const { dataForm, errorForm } = await supabase
            .from('docs')
            .insert([
                {
                btnName: "View Bulletin",
                docLink: StorageLink,
                }
            ])
            .select();

            if (dataForm) {
            console.log("Event created successfully:", data);
            }

            if (errorForm) {
            console.log("Error creating event:", error);
            }
        }

        if (formType === "homily"){
            console.log("homily submit")
            const { dataForm, errorForm } = await supabase
            .from('docs')
            .insert([
                {
                btnName: "Read Homily",
                docLink: StorageLink,
                }
            ])
            .select();

            if (dataForm) {
            console.log("Event created successfully:", data);
            }

            if (errorForm) {
            console.log("Error creating event:", error);
            }
        }

        fetchDocs();
    }

    
    return (
        <div className='SundayHomily'>
            {isAuthenticated && (
                <div>
                <form action="bulletin" onSubmit={handleDocFormSubmit}>
                    <input type="file" id="fileName" />
                    <button type='submit'>update bulletin</button>
                </form>
                 <form action="homily" onSubmit={handleDocFormSubmit}>
                 <input type="file" />
                 <button type='submit'>update homily</button>

             </form>
             </div>
            )}


{fetchError && <p>{fetchError}</p>}
      {docs && (
        <ul>
          {docs
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((doc) => (
              <li key={doc.id}>
                <a 
                className='MainBTN' 
                href={doc.docLink} 
                target="_blank"
                rel="noreferrer"
                >
                📄{doc.btnName}
                </a>
                {isAuthenticated && (
                <button 
                    onClick={async () => {
                        const { error } = await supabase
                            .from('docs')
                            .delete()
                            .eq('id', doc.id);

                        if (error) {
                            console.log("Error deleting document:", error);
                        } else {
                            setDocs(docs.filter((d) => d.id !== doc.id));
                            console.log("Document deleted successfully");
                        }
                    }}
                >
                    Delete
                </button>
                )}
              </li>
            ))}
        </ul>
      )}

            {/* <p className='span2'>03/16/2025</p>

            <main>
                <a className='MainBTN' href={`/assets/Homily/H2025-3-30.docx`} download>
                📄Read Homily  
                </a>


                <a className='MainBTN' href={`/assets/Bulletin/2025/2025-3-30.pdf`} target="_blank" rel="noopener noreferrer">📄View Bulletin 
                 </a>

              
            </main> */}

        </div>
    );
}

export default Homily;